export const SEARCH_USERS = "SEARCH_USERS";
export const GET_USER = "GET_USER";
export const CLEAR_USERS = "CLEAR_USERS";
export const GET_REPOS = "GET_REPOS";
export const SET_LOADING = "SET_LOADING";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const GET_EPISODES = "GET_EPISODES";
export const GET_EPISODE = "GET_EPISODE";
